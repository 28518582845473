import React, { useEffect } from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import Add from './Add';
import Edit from './Edit';
import { useCategorys } from '../Categorys/Store';

function Subcats(props) {
    let { subcatColumns, subcats, setSubCategorys, setCategorys, removeCategory, setEd } = useCategorys()

    useEffect(() => {
        setSubCategorys()
        setCategorys()
    }, [])
    return (
        <div>

            <MainTable
                pageTitle={'Kategoriyalar'}
                defkey={'id'}
                pcolumns={subcatColumns}
                pdata={subcats}
                add={<Add />}
                onDelete={removeCategory}
                setEd={setEd}
                edit={<Edit />}
                onEdit={(rec) => {
                    setEd(rec)
                }}
            />
        </div>
    );
}

export default Subcats;