import { PhoneOutlined } from '@ant-design/icons';
import { Button, Divider, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function OrderDetails({ order }) {
    let [items, setItems] = useState([])
    useEffect(() => {
        axios.get(`/orders/${order.id}`).then(res => {
            setItems(res.data)
        })
    }, [])
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>№ {order.id} - {order.fullname}</h3>
                <Button type='primary' icon={<PhoneOutlined />}><a href={'tel:' + order.phone}>{order.phone}</a></Button>
            </div>
            <Divider />
            <p><b>Viloyat:</b> {order.state}</p>
            <p><b>Shahar: </b>{order.city}</p>
            <p><b>Manzil: </b>{order.address}</p>
            <Divider />
            <p><b>Email:</b> {order.email}</p>
            <p><b>Buyurtma vaqti:</b> {order.created_at}</p>
            <Table
                rowKey={'id'}
                columns={[
                    { title: 'Mahsulot', dataIndex: 'product_name', key: 'product' },
                    { title: 'Narxi', dataIndex: 'product_price', key: 'price' },
                    { title: 'Soni', dataIndex: 'product_quantity', key: 'quantity' },
                    { title: 'Jami', dataIndex: 'total', key: 'total', render: (text, record) => <b>{record.product_price * record.product_quantity} so'm</b> },
                ]}
                dataSource={items}
            />

            <Button type='text' block style={{
                backgroundColor: (order.status === 'yangi')
                    ? '#f5f5f5'
                    : (order.status === 'qabul qilindi')
                        ? 'lightblue'
                        : (order.status === 'tayyorlandi')
                            ? 'lightgreen'
                            : (order.status === 'yetkazib berildi')
                                ? 'lightcoral'
                                : 'lightgray'
            }} >{order.status}</Button>
        </div>
    );
}

export default OrderDetails;