import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Switch, Select, Row, Col } from 'antd';
import { useProducts } from './Store';
import { UploadFile } from '../../Components/Upload/UploadFile';
import { SunEdit } from '../../Components/SunEdit';
import axios from 'axios';

function Add(props) {
    let { addProduct } = useProducts()
    let [img, setImg] = useState('')
    let [descuz, setDescUz] = useState('')
    let [descru, setDescRu] = useState('')
    let [cats, setCats] = useState([])
    let [subcats, setSubCats] = useState([])
    let [subcatsA, setSubCatsA] = useState([])
    let [units, setUnits] = useState([])
    let [load, setLoad] = useState(false)

    useEffect(() => {
        axios.get('categories').then(res => {
            setCats(res.data)
        })
        axios.get('units').then(res => {
            setUnits(res.data)
        })
        axios.get('subcats').then(res => {
            setSubCats(res.data)
        })
    }, [])
    const onFinish = (values) => {
        setLoad(true)
        let data = { ...values, description_uz: descuz, description_ru: descru, image: img }
        // console.log(data);
        setTimeout(() => {
            setLoad(false)
        }, 2000);
        addProduct(data)
    }

    return (
        <Form
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
        >
            <fieldset>
                <legend>Umumiy Informatsiya</legend>

                <Form.Item
                    label="Nomi (O'zbekcha)"
                    name="name_uz"
                    rules={[{ required: true, message: 'Please enter the product name (English)' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nomi (Ruscha)"
                    name="name_ru"
                    rules={[{ required: true, message: 'Please enter the product name (Russian)' }]}
                >
                    <Input />
                </Form.Item>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label="Xajmi"
                            name="weight"
                            rules={[{ required: true, message: 'Please enter the product name (Russian)' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Xajmi Ruscha"
                            name="weightru"
                            rules={[{ required: true, message: 'Please enter the product name (Russian)' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    label="Batafsil (O'zbekcha)"
                    name="description_uz"
                >
                    <SunEdit setter={setDescUz} />
                </Form.Item>

                <Form.Item
                    label="Batafsil (Ruscha)"
                    name="description_ru"
                >
                    <SunEdit setter={setDescRu} />
                </Form.Item>
                <Row gutter={10}>
                    <Col span={8}>
                        <Form.Item
                            label="Asosiy Kategoriya"
                            name={'parentcat_id'}
                            rules={[{ required: true, message: 'Please enter the category ID' }]}
                        >
                            <Select onChange={(i) => {
                                console.log(i);
                                setSubCatsA(subcats.filter(item => item.parent_id === i))
                            }}>
                                {cats?.map(item => (
                                    <Select.Option key={item.id} value={item.id}>{item.name_uz}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Sub Kategoriya"
                            name="category_id"
                            rules={[{ required: true, message: 'Please enter the category ID' }]}
                        >
                            <Select>
                                {subcatsA?.map(item => (
                                    <Select.Option key={item.id} value={item.id}>{item.name_uz}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Olchov birligi"
                            name="unit_id"
                        >
                            <Select>
                                {units?.map(item => (
                                    <Select.Option key={item.id} value={item.id}>{item.name_uz}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>



                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label="Narxi"
                            name="price"
                            rules={[{ required: true, message: 'Please enter the price' }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Skidkadagi narxi"
                            name="sale_price"
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    label="Rasmini yuklang"
                    name="image"
                >
                    <UploadFile setFile={setImg} />
                </Form.Item>
            </fieldset>
            <fieldset>
                <legend>Yorliqlar</legend>
                <Row gutter={10}>
                    <Col span={4}>
                        <Form.Item
                            label="Tanlangan"
                            name="is_featured"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item></Col>
                    <Col span={4}>
                        <Form.Item
                            label="Topda"
                            name="is_top_selling"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Ommabop"
                            name="is_popular"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Yangi"
                            name="is_new"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Skidkada"
                            name="is_sale"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            </fieldset>

            <fieldset>
                <legend>SEO sozlamalari</legend>

                <Form.Item
                    label="SEO kalit so'zlar"
                    name="meta_keywords"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="SEO kalit so'zlar ruscha"
                    name="meta_keywords_ru"
                >
                    <Input />
                </Form.Item>
            </fieldset>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={load}>
                    Saqlash
                </Button>
            </Form.Item>

        </Form>
    )
}

export default Add;