import { Image } from "antd";
import axios from "axios";
import { create } from "zustand";

export const useCategorys = create((set, get) => ({
    ed: {},
    setEd: (ed) => set({ ed }),
    categorys: [],
    subcats: [],
    subcatColumns: [
        {
            title: 'Rasmi',
            dataIndex: 'image',
            key: 'image',
            render: (text, record) => <Image src={process.env.REACT_APP_MAINURL + record.image} alt={record.name_uz} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />,
        },
        {
            title: 'Asosiy Kategoriyasi',
            dataIndex: 'parent_id',
            key: 'parent_id',
            render: (text, record) => (<span style={{ padding: '8px 10px', backgroundColor: 'aquamarine' }}>{get().getCategoryById(record.parent_id)?.name_uz}</span>)
        },
        {
            title: 'Kategoriya nomi RU',
            dataIndex: 'name_uz',
            key: 'name_uz',
            editable: true,
        },
        {
            title: 'Kategoriya nomi RU',
            dataIndex: 'name_ru',
            key: 'name_ru',
            editable: true,
        }
    ],
    columns: [
        {
            title: 'Rasmi',
            dataIndex: 'image',
            key: 'image',
            render: (text, record) => <Image src={process.env.REACT_APP_MAINURL + record.image} alt={record.name_uz} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />,
        },
        {
            title: 'Kategoriya nomi RU',
            dataIndex: 'name_uz',
            key: 'name_uz',
            editable: true,
        },
        {
            title: 'Kategoriya nomi RU',
            dataIndex: 'name_ru',
            key: 'name_ru',
            editable: true,
        }
    ],
    setCategorys: () => {
        axios.get('categories').then(res => {
            set({ categorys: res.data })
        })
    },
    setSubCategorys: () => {
        axios.get('subcats').then(res => {
            set({ subcats: res.data })
        })
    },
    addCategory: (category) => {
        axios.post('categories', category).then(res => {
            get().setCategorys()
            get().setSubCategorys()
        })
    },
    removeCategory: (_, id) => {
        axios.delete('categories/' + id.id).then(res => {
            if (res.status === 200) {
                get().setCategorys()
                get().setSubCategorys()
            }
        })
    },
    updateCategory: (category) => {
        axios.put('categories/' + category.id, category).then(res => {
            if (res.status === 200) {
                get().setCategorys()
                get().setSubCategorys()
            }
        })
    },
    getCategorys: () => get().categorys,
    getCategoryById: (id) => get().categorys.find(item => item.id === id),
    getCategorysLength: () => get().categorys.length
}))
