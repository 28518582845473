import { Button, Checkbox, Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { UploadFile } from '../../Components/Upload/UploadFile';
import { useCategorys, useSlides } from './Store';
import { useForm } from 'antd/es/form/Form';

function Edit(props) {
    let [img, setImg] = useState('')
    let { editSlide, ed } = useSlides()
    let [form] = useForm()
    useEffect(() => {
        setImg(ed.image)
        form.setFieldsValue(ed)
    }, [ed])
    return (
        <div>
            <Form onFinish={(v) => {
                editSlide({ ...v, image: img })
             }}
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Form.Item
                    name="id"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Slider sarlavhasi - UZ"
                    name="title_uz"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Slider sarlavhasi - RU"
                    name="title_ru"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Slider Izohi - UZ"
                    name="desc_uz"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Slider Izohi - RU"
                    name="desc_ru"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="URL manzil"
                    name="link"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Rasmi"
                    name="image"
                >
                    <UploadFile img={img} setFile={setImg} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Qo'shish
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Edit;