import { Button, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { UploadFile } from '../../Components/Upload/UploadFile';
import { useCategorys } from './Store';

function Add(props) {
    let [img, setImg] = useState('')
    let { addCategory } = useCategorys()
    return (
        <div>
            <Form onFinish={(v) => {
                addCategory({ ...v, image: img })
             }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Form.Item
                    label="Kategorya nomi - UZ"
                    name="name_uz"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Katigoriya nomi - RU"
                    name="name_ru"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Rasmi"
                    name="image"
                >
                    <UploadFile setFile={setImg} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Qo'shish
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Add;