import { Button, Card, Col, Divider, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import OrderDetails from './OrderDetails';
import { PhoneOutlined } from '@ant-design/icons';

function OrderCard({ order }) {
    let [visible, setVisible] = useState(false)
    return (
        <>
            <Modal title="Buyurtma" open={visible} footer={null} width={1000} onCancel={() => { setVisible(false) }}>
                <OrderDetails order={order} />
            </Modal>
            <Card title={(
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>№ {order.id} - {order.fullname}</h3>
                    <Button variant={'outlined'} icon={<PhoneOutlined />}><a href={'tel:' + order.phone}>{order.phone}</a></Button>
                </div>
            )} bordered={false}
                actions={[
                    <Button type='primary' onClick={() => {
                        setVisible(true)
                    }} block>Batafsil</Button>,
                ]}
            >

                <p><b>Viloyat:</b> {order.state}</p>
                <p><b>Shahar: </b>{order.city}</p>
                <p><b>Manzil: </b>{order.address}</p>
                <Divider />
                <p><b>Email:</b> {order.email}</p>
                <p><b>Buyurtma vaqti:</b> {order.created_at}</p>

                <p>{order.comment}</p>
                <Divider />
                <Row gutter={15}>
                    <Col span={12}>
                        <Select  style={{width: '100%'}} placeholder="To'lov holati" defaultValue={order.status}>
                            <Select.Option value="tayyor">Tayyor</Select.Option>
                            <Select.Option value="yetkazib berildi">Yetkazib berildi</Select.Option>
                        </Select>
                    </Col>
                    <Col span={12}>
                        <Select style={{width: '100%'}} placeholder="Yetkazish holati" defaultValue={order.paystatus}>
                            <Select.Option value="tayyor">Tayyor</Select.Option>
                            <Select.Option value="yetkazib berildi">Yetkazib berildi</Select.Option>

                        </Select>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default OrderCard;