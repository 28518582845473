import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Image, Input, Row, Upload } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { UploadFile } from '../../Components/Upload/UploadFile';
import { useForm } from 'antd/es/form/Form';

function Configs(props) {
    let [configs, setConfigs] = useState([])

    useEffect(() => {
        axios.get('/configs').then(res => {
            setConfigs(res.data)
        })
    }, [])
    console.log(configs);


    return (
        <div>
            {configs.map(item => (item.valuetype === 'text') ? <TextForm key={item.id} item={item} /> : <ImageForm key={item.id} item={item} />)}
        </div>
    );
}

export default Configs;


function TextForm({ item }) {
    let [frm] = useForm()
    useEffect(() => {
        frm.setFieldsValue(item)
    }, [item])
    return (
        <Card key={item.id} title={item.id + ' ' + item.izoh}>
            <Form
                form={frm}
                onFinish={(v) => {
                    axios.put(`/configs/${item.id}`, v).then(res => {
                        console.log(res);
                    })
                }}
                labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Row gutter={10}>
                    <Col span={11}>
                        <Form.Item name={'value_uz'} label="O'zbekcha qiymat">
                            <Input type="text" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item name={'value_ru'} label="Ruscha qiymat">
                            <Input type="text" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item label="Saqlash">
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

function ImageForm({ item }) {
    let [img, setImg] = useState('')
    let [frm] = useForm()
    useEffect(() => {
        setImg(item.value_uz)
        frm.setFieldsValue(item)
    }, [item])
    return (
        <Card key={item.id} title={item.id + ' ' + item.izoh}>
            <Form form={frm} onFinish={(v)=>{
                axios.put(`/configs/${v.id}`, {value_uz: img}).then(res => {
                    console.log(res);
                })
            }} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Form.Item name={'id'} hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={10}>
                    <Col span={22}>
                        <Form.Item label="Ruscha qiymat">
                            <UploadFile img={img} setFile={setImg} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item label="Saqlash">
                            <Button type="primary" htmlType='submit' icon={<SaveOutlined />} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}