import React, { useEffect } from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import { useUnits } from './Store';
import Add from './Add';
import Edit from './Edit';

function Units(props) {
    let { units, columns, getUnits, deleteUnit, setEd } = useUnits()
    useEffect(() => {
        getUnits()
    }, [])
    return (
        <div>
            <MainTable
                pageTitle={"O'lchov birliklari"}
                pdata={units}
                pcolumns={columns}
                add={<Add />}
                onDelete={deleteUnit}
                edit={<Edit />}
                defkey={'id'}
                setEd={setEd}
                onEdit={(v) => {
                    setEd(v)
                }}
            />

        </div>
    );
}

export default Units;