import {Button, Form, Input, message} from "antd";
import {useAuthHeader, useSignIn} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import axios from "axios";

export const LoginForm = () => {
    const authHeader = useAuthHeader()
    axios.defaults.baseURL = process.env.REACT_APP_URL;
    axios.defaults.headers.common['Authorization'] = authHeader();
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    const signIn = useSignIn()
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const onFinish = (values) => {

        axios.post('/login', values).then(data => {
            if (signIn(
                {
                    token: data.data.access_token,
                    expiresIn: data.data.expires_in,
                    tokenType: data.data.token_type,
                    authState: data.data.user,
                }
            )) {
                navigate("/main");
            } else {
                navigate("/login");
            }
        }).catch(data => {
            messageApi.open({
                type: 'error',
                content: data.message,
            });
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
            {contextHolder}
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
            >
                <Form.Item
                    label="Login"
                    name="login"
                    rules={[
                        {
                            required: true,
                            message: "Iltimos Email manzilini kiriting",
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Parol"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Iltimos parolni kiriting",
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        KIRISH
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}