import { Button, Form, Input, Modal, message } from "antd";
import { LoginForm } from "./LoginForm";
import { useState } from "react";
import { CheckOutlined, LoginOutlined } from "@ant-design/icons";
import ithub from './../../assets/images/ithub.png'
import { useNavigate } from "react-router-dom";


export default function LoginLayout() {

    return (
        <>

            <div style={{
                backgroundImage: 'url(/assets/images/loginback.jpg)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh'
            }}>
                <div style={{ 
                    width: '400px',
                    padding: '20px',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '25px' }}>
                        {/* <img src={ithub} style={{ width: '200px' }} alt="" /> */}
                        <h2>KIRISH</h2>
                    </div>
                    <LoginForm />
                </div>

            </div>
        </>

    )
}