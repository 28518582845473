import { Image } from "antd";
import axios from "axios";
import { render } from "katex";
import { create } from "zustand";

export let useSlides = create((set, get) => ({
    columns: [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text) => <Image src={process.env.REACT_APP_MAINURL + text} alt="" style={{ width: 100 }} />
        },
        {
            title: 'Sarlavha UZ',
            dataIndex: 'title_uz',
            key: 'title_uz',
        },
        {
            title: 'Sarlavha RU',
            dataIndex: 'title_ru',
            key: 'title_ru',
        },
        {
            title: 'Izoh UZ',
            dataIndex: 'desc_uz',
            key: 'desc_uz',
        },
        {
            title: 'Izoh RU',
            dataIndex: 'desc_ru',
            key: 'desc_ru',
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: (text) =><a href={text} target="_blank">{text}</a>
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     render: (text) => text ? 'Aktiv' : 'Deactiv'
        // },
       
    ],
    slides: [],
    ed: {},
    setEd: (ed) => set({ ed }),
    setSlides: () => {
        axios.get('/sliders').then(res => {
            set({ slides: res.data })
        }).catch(err => {
            console.log(err)
        })
    },
    addSlide: (slide) => {
        console.log(slide);
        
        axios.post('/sliders', slide).then(res => {
            get().setSlides()
        }).catch(err => {
            console.log(err)
        })
    },
    deleteSlide: (_, id) => {
        axios.delete(`/sliders/${id.id}`).then(res => {
            get().setSlides()
        }).catch(err => {
            console.log(err)
        })
    },
    editSlide: (slide) => {
        axios.put(`/sliders/${slide.id}`, slide).then(res => {
            get().setSlides()
        }).catch(err => {
            console.log(err)
        })
    },
}))