import {MainTable} from "../../Components/MainTable/MainTable";
import {useEffect, useState} from "react";
import axios from "axios";
import {useAuthHeader} from "react-auth-kit";
import {AddEmployee} from "./AddEmployee";
import {redirect} from "react-router-dom";
import {message} from "antd";
import {EditEmployee} from "./EditEmployee";

export const Employee = () => {
    let [users, setUsers] = useState([])
    let [rend, setRend] = useState(true)
    let [editData, setEditData] = useState({})
    useEffect(() => {
        axios.get('/users').then(data => setUsers(data.data)).catch(error => {
            redirect('/login')
        })
    }, [rend]);
    let columns = [
        {
            title: 'FISH',
            dataIndex: 'fish',
            key: 'fish'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Login',
            dataIndex: 'login',
            key: 'login'
        },
        {
            title: 'Mansabi',
            dataIndex: 'role',
            key: 'role'
        },
        {
            title: 'JShShIR',
            dataIndex: 'jshshir',
            key: 'jshshir'
        },
        {
            title: 'Telefon',
            dataIndex: 'tel',
            key: 'tel'
        },
        {
            title: 'Manzil',
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: 'Shartnoma',
            dataIndex: 'contract_no',
            key: 'contract_no',
            render: (_, rec) => {
                return (<>№ {rec.contract_no} vaqti: {rec.contract_date}</>)
            }
        },
    ]
    const onDelete = (e, rec) => {
        axios.get('/userdelete/' + rec.id).then(data=>{
            if(data.status === 200){
                message.success(`Ma'lumot muvaffaqiyatli O'chirildi`);
                setRend(!rend)
            }
        }).catch(data=>{
            message.error(`Ma'lumot muvaffaqiyatli O'chirildi`);
        })
    }
    const onEdit = (rec)=>{
        setEditData(rec)
    }
    return (
        <>
            <MainTable
                pageTitle={'Xodimlar'}
                onDelete={onDelete}
                defkey={'id'}
                pdata={users}
                pcolumns={columns}
                add={<AddEmployee rend={rend} setR={setRend}/>}
                edit={<EditEmployee rend={rend} setR={setRend} editData={editData} />}
                onEdit={onEdit}
                setEd={setEditData}
            />
        </>
    )
}