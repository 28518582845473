import { Button, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { UploadFile } from '../../Components/Upload/UploadFile';
import { useCategorys } from './Store';
import { useForm } from 'antd/es/form/Form';

function Edit() {
    let { updateCategory, ed } = useCategorys()
    let [img, setImg] = useState('')

    let [form] = useForm()
    useEffect(() => {
        setImg(ed.image)
        form.setFieldsValue(ed)
    }, [ed]);
    return (
        <div>
            <Form
                onFinish={(v) => {
                    updateCategory({ ...v, image: img })
                }}
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Form.Item
                    label="ID"
                    hidden={true}
                    name="id"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Kategorya nomi - UZ"
                    name="name_uz"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Katigoriya nomi - RU"
                    name="name_ru"
                    rules={[{ required: true, message: 'Please enter the category name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Rasmi"
                    name="image"
                >
                    <UploadFile img={img} setFile={setImg} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        O'zgartirish
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Edit;