import axios from "axios";
import { create } from "zustand";

export const useUnits = create((set, get) => ({
    units: [],
    ed: {},
    setEd: (ed) => {
        set({ ed })
    },
    columns: [
        {
            title: 'Nomi UZ',
            dataIndex: 'name_uz',
            key: 'name_uz',
        },
        {
            title: 'Qisqartma UZ',
            dataIndex: 'slug_uz',
            key: 'slug_uz',
        },
        {
            title: 'Nomi RU',
            dataIndex: 'name_ru',
            key: 'name_ru',
        },
        {
            title: 'Qisqartma RU',
            dataIndex: 'slug_ru',
            key: 'slug_ru',
        },

    ],
    getUnits: async () => {
        axios.get('units').then(response => {
            set({ units: response.data })
        }).catch(e => {
            console.log(e)
        })
    },
    createUnit: async (unit) => {
        axios.post('units', unit).then(response => {
            get().getUnits()
        }).catch(e => {
            console.log(e)
        })
    },
    updateUnit: async (unit) => {
        axios.put(`units/${unit.id}`, unit).then(response => {
            get().getUnits()
        }).catch(e => {
            console.log(e)
        })
    },
    deleteUnit: async (_, unit) => {
        axios.delete(`units/${unit.id}`).then(response => {
            get().getUnits()
        }).catch(e => {
            console.log(e)
        })
    }
}))