import { AdminLayout } from "./Layyouts/AdminLayout";
import LoginLayout from "./Layyouts/AuthLayout/LoginLayout";
import { redirect, Route, Routes } from "react-router-dom";
import { RequireAuth, useAuthUser, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { useEffect, useState } from "react";

function App() {
    let [layout, setLayout] = useState('')
    const isAuthenticated = useIsAuthenticated()
    const auth = useAuthUser()
    const signOut = useSignOut()
    useEffect(() => {
        if (auth()) {
            setLayout()
        } else {
            signOut()
            redirect('/login')
        }
    }, []);


    // console.log(auth())
    if (isAuthenticated()) {
        return (
            <>
                <Routes>
                    <Route path={'/'} element={<LoginLayout />} />
                    <Route path={'/main/*'} element={
                        <RequireAuth loginPath={'/login'}>
                            <AdminLayout />
                        </RequireAuth>
                    } />
                </Routes>
            </>
        )
    } else {
        return (
            <Routes>
                <Route path={'/'} element={<LoginLayout />} />
            </Routes>
        )
    }
}

export default App;
