import React, { useEffect, useState } from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import axios from 'axios';
import { Button, Col, Form, Input, message, Row, Table } from 'antd';

function City(props) {
    let [tumans, setTumans] = useState([])
    let [rend, setRend] = useState(false)

    useEffect(() => {
        axios.get('tumans')
            .then(res => {
                setTumans(res.data)
            })
    }, [])

    let columns = [
        {
            title: 'Viloyat',
            dataIndex: 'vilname',
            key: 'vilname',
        },
        {
            title: 'Amal',
            dataIndex: '',
            key: '',
            render: (_, t) => (
                <div>
                    <Form initialValues={t} onFinish={(v) => {
                        axios.put(`tumans/${t.id}`, v).then(res => {
                            setRend(!rend)
                            message.success('Narx yangilandi')
                        })
                    }}>
                        <h1>{t.nameuz}</h1>
                        <Form.Item name={'id'} hidden>
                            <Input type='number' />
                        </Form.Item>
                        <Row>
                            <Col span={14}>
                                <Form.Item name={'deliver_price'}>
                                    <Input type='number' />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Button htmlType='submit' type='primary'>Narxni yangilash</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )
        }
    ];
    console.log(tumans);

    return (
        <div>
            <h2 style={{ marginBottom: '15px' }}>Tumanlar uchun yetkazish narxlari</h2>
            <Table
                columns={columns}
                dataSource={tumans}
                rowKey={'id'}
            />
        </div>
    );
}

export default City;