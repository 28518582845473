import axios from "axios";
import parse from "html-react-parser";
import { create } from "zustand";

export const usePages = create((set, get) => ({
    pages: [],
    ed: {},
    setEd: (ed) => {
        set({ ed })
    },  
    columns: [
        {
            title: 'Nomi UZ',
            dataIndex: 'name_uz',
            key: 'name_uz',
        },
        {
            title: 'Nomi RU',
            dataIndex: 'name_ru',
            key: 'name_ru',
        },
        {
            title: 'Kontent UZ',
            dataIndex: 'page_uz',
            key: 'page_uz',
            width: '30%',
            render: (text, record) => {
                return (
                    <div style={{ height: '200px', overflow: 'auto' }}>
                        {parse(record.page_uz)}
                    </div>
                )
            }
        },
        {
            title: 'Kontent RU',
            dataIndex: 'page_ru',
            key: 'page_ru',
            width: '30%',
            render: (text, record) => {
                return (
                    <div style={{ height: '200px', overflow: 'auto' }}>
                        {parse(record.page_ru)}
                    </div>
                )
            }
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <div>
                        {record.status ? 'Faol' : 'Faol emas'}
                    </div>
                )
            }
        },

    ],
    setPages: () => {
        axios.get('/pages')
            .then(res => {
                set({ pages: res.data })
            })
            .catch(err => {
                console.log(err)
            })
    },
    addPage: (page) => {
        axios.post('/pages', page)
            .then(res => {
                get().setPages()
            })
            .catch(err => {
                console.log(err)
            })
    },
    updatePage: (page) => {
        axios.put(`/pages/${page.id}`, page)
            .then(res => {
                get().setPages()
            })
            .catch(err => {
                console.log(err)
            })
    },
    deletePage: (id) => {
        axios.delete(`/pages/${id}`)
            .then(res => {
                get().setPages()
            })
            .catch(err => {
                console.log(err)
            })
    },
}))