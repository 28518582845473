import { Button, Layout, Menu, message, Space } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import {
    LogoutOutlined,
    FileProtectOutlined, ProfileOutlined, SettingOutlined, FileAddOutlined,
} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { useAuthHeader, useSignOut } from "react-auth-kit";
import { Employee } from "../Pages/Employees/Employee";
import axios from "axios";
import { useState } from "react";
import Categorys from "../Pages/Categorys/Categorys";
import Subcats from "../Pages/SubCats/Subcats";
import Products from "../Pages/Products/Products";
import Main from "../Pages/Main/Main";
import Units from "../Pages/Units/Units";
import Pages from "../Pages/Pages/Pages";
import Slides from "../Pages/Slides/Slides";
import Configs from "../Pages/Configs/Configs";
import Orders from "../Pages/Orders/Orders";
import City from "../Pages/City/City";


export const AdminLayout = () => {
    let [collapsed, setCollapsed] = useState(false)
    const authHeader = useAuthHeader()
    axios.defaults.baseURL = process.env.REACT_APP_URL
    axios.defaults.headers.common['Authorization'] = authHeader();
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const signOut = useSignOut()


    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error === null) {
            message.error("Bunday Ma'lumotlar topilmadi")
        } else if (error.response.status === 401) {
            signOut()
        }
        return error;
    });

    let navigate = useNavigate()
    return (
        <>
            <Layout>
                <Header
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '30px',
                        paddingInline: '10px',
                        // backgroundColor: '#23334f'
                    }}
                >
                    <div style={{ lineHeight: '0', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <img style={{ height: '24px' }} src={'/assets/images/logo.png'} alt={'stebiz'} />
                        <span style={{ fontSize: '16px', color: '#ff9300' }}>SHOP UZ</span>
                    </div>
                    {/*<Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={items1}/>*/}
                    <Space>
                        {/* <Button type={'primary'} shape={'circle'} icon={<UserOutlined />} size={'small'} /> */}
                        <Button onClick={() => {

                            signOut()
                            navigate('/')

                        }} type={'primary'} shape={'circle'} icon={<LogoutOutlined />}
                            size={'small'} danger />
                    </Space>
                </Header>
                <Layout>
                    <Sider
                        width={200}
                        collapsible
                        collapsed={collapsed}
                        onCollapse={(value) => setCollapsed(value)}
                    >
                        <Menu
                            theme="light"
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{
                                height: '100%', borderRight: 0,
                            }}
                            items={[
                                {
                                    id: 'dash',
                                    label: <NavLink to={'/main'}>Kabinet</NavLink>,
                                    icon: <FileProtectOutlined />
                                },
                                {
                                    id: 'serts',
                                    label: <NavLink to={'/main/orders'}>Buyurtmalar</NavLink>,
                                    icon: <ProfileOutlined />
                                },
                                {
                                    key: 'dav',
                                    label: <NavLink to={"/main/product"}>Maxsulotlar</NavLink>,
                                    icon: <SettingOutlined />,
                                },
                                {
                                    key: 'settings',
                                    icon: <SettingOutlined />,
                                    label: <NavLink to={"/main/category"}>Sozlamalar</NavLink>,
                                    children: [
                                        {
                                            key: 'set1',
                                            icon: <FileAddOutlined />,
                                            label: <NavLink to="/main/units">O'lchov birliklari</NavLink>,
                                        },
                                        {
                                            key: 'set2',
                                            icon: <FileAddOutlined />,
                                            label: <NavLink to="/main/category">Kategoriyalar</NavLink>,
                                        },
                                        {
                                            key: 'set3',
                                            icon: <FileAddOutlined />,
                                            label: <NavLink to="/main/subcategory">Sub Kategoriyalar</NavLink>,
                                        },
                                        {
                                            key: 'set4',
                                            icon: <FileAddOutlined />,
                                            label: <NavLink to="/main/pages">Sahifalar</NavLink>,
                                        },
                                        {
                                            key: 'set5',
                                            icon: <FileAddOutlined />,
                                            label: <NavLink to="/main/slides">Asosiy Slayder</NavLink>,
                                        },
                                        {
                                            key: 'set7',
                                            icon: <FileAddOutlined />,
                                            label: <NavLink to="/main/deliver">Yetkazish narxlari</NavLink>,
                                        },
                                        {
                                            key: 'set6',
                                            icon: <FileAddOutlined />,
                                            label: <NavLink to="/main/configs">Sayt Konfiguratsiyalari</NavLink>,
                                        },
                                    ]
                                },

                            ]}
                        />
                    </Sider>
                    <Layout
                        style={{
                            padding: '10px',
                            backgroundColor: '#87cefa22'
                        }}
                    >
                        <Content
                            style={{
                                padding: 10, margin: 0, minHeight: '94vh',
                            }}
                        >
                            <Routes>
                                <Route path={'/'} element={<Main />} />
                                <Route path={'/category'} element={<Categorys />} />
                                <Route path={'/units'} element={<Units />} />
                                <Route path={'/pages'} element={<Pages />} />
                                <Route path={'/subcategory'} element={<Subcats />} />
                                <Route path={'/product'} element={<Products />} />
                                <Route path={'/users'} element={<Employee />} />
                                <Route path={'/slides'} element={<Slides />} />
                                <Route path={'/orders'} element={<Orders />} />
                                <Route path={'/configs'} element={<Configs />} />
                                <Route path={'/deliver'} element={<City />} />
                            </Routes>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}