import { Button, Form, Input } from 'antd';
import React from 'react';
import { useUnits } from './Store';

function Add(props) {
    let {createUnit} = useUnits()
    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={createUnit}
                autoComplete="off"
            >
                <Form.Item
                    label="O'lchov birlik nomi UZ"
                    name="name_uz"
                    rules={[{ required: true, message: "Iltimos, o'lchov birlik nomini kiriting" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="O'lchov birlik nomi RU"
                    name="name_ru"
                    rules={[{ required: true, message: "Iltimos, o'lchov birlik nomini kiriting" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="O'lchov birlik qisqartmasi UZ"
                    name="slug_uz"
                    rules={[{ required: true, message: "Iltimos, o'lchov birlik qisqartmasini kiriting" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="O'lchov birlik qisqartmasi RU"
                    name="slug_ru"
                    rules={[{ required: true, message: "Iltimos, o'lchov birlik qisqartmasini kiriting" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default Add;