import React, { useEffect } from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import { useSlides } from './Store';
import Add from './Add';
import Edit from './Edit';

function Slides() {

    let {slides, columns, setEd, deleteSlide, setSlides} = useSlides()

    useEffect(() => {
            setSlides()
    }, [])
    return (
        <div>

            <MainTable
                pdata={slides}
                pcolumns={columns}
                defkey={'id'}
                add={<Add />}
                onDelete={deleteSlide}
                onEdit={(v)=>{
                    setEd(v)
                }}
                edit={<Edit />}
                setEd={setEd}
                pageTitle={'Asosiy Slider'}
            />

        </div>
    );
}

export default Slides;