import React, { useEffect } from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import Add from './Add';
import Edit from './Edit';
import { useProducts } from './Store';

function Products(props) {
    let { products, setProducts, deleteProduct, columns, setEd} = useProducts()
    useEffect(() => {
        setProducts()
    }, [])
    return (
        <div>
            <MainTable 
                pageTitle={'Mahsulotlar'}
                add={<Add />}
                edit={<Edit />}
                onDelete={(_, rec)=>deleteProduct(rec)}
                pdata={products}
                pcolumns={columns}
                xwidth={2500}
                setEd={setEd}
                defkey={'id'}
                onEdit={(record) =>  setEd(record)}
            />
        </div>
    );
}

export default Products;