import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Switch, Select, Row, Col } from 'antd';
import { usePages } from './Store';
import { SunEdit } from '../../Components/SunEdit';
import { useForm } from 'antd/es/form/Form';

function Edit() {
    let { updatePage, ed } = usePages()
    let [descuz, setDescUz] = useState('')
    let [descru, setDescRu] = useState('')

    let [form] = useForm()

    const onFinish = (values) => {
        let data = { ...values, page_uz: descuz, page_ru: descru }
        updatePage(data)
    }

    useEffect(() => {
        form.setFieldsValue(ed)
        setDescUz(ed.page_uz)
        setDescRu(ed.page_ru)
    }, [ed])

    return (
        <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
        >
            <fieldset>
                <legend>Umumiy Informatsiya</legend>

                <Form.Item
                    label="Nomi (O'zbekcha)"
                    name="name_uz"
                    rules={[{ required: true, message: 'Please enter the product name (English)' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nomi (Ruscha)"
                    name="name_ru"
                    rules={[{ required: true, message: 'Please enter the product name (Russian)' }]}
                >
                    <Input />
                </Form.Item>

                <p>Kontent UZ</p>
                <SunEdit content={descuz} setter={setDescUz} />

                <p>Kontent RU</p>
                <SunEdit content={descru} setter={setDescRu} />

            </fieldset>
            <fieldset>
                <legend>Yorliqlar</legend>
                <Row gutter={10}>
                    <Col span={4}>
                        <Form.Item
                            label="Sahifani ko'rsatish"
                            name="status"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item></Col>

                    <Col span={4}>
                        <Form.Item
                            label="Ssilka"
                            name="link"
                            rules={[{ required: true, message: 'Please enter the link' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </fieldset>

            <fieldset>
                <legend>SEO sozlamalari</legend>

                <Form.Item
                    label="SEO nomi"
                    name="meta_title"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="SEO kalit so'zlar"
                    name="meta_keywords"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="SEO tavsifi"
                    name="meta_description"
                >
                    <Input.TextArea />
                </Form.Item>

            </fieldset>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Saqlash
                </Button>
            </Form.Item>

        </Form>
    )
}

export default Edit;