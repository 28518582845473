import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Switch, Select, Row, Col } from 'antd';
import { usePages } from './Store';
import { UploadFile } from '../../Components/Upload/UploadFile';
import { SunEdit } from '../../Components/SunEdit';
import axios from 'axios';

function Add(props) {
    let { addPage } = usePages()
    let [descuz, setDescUz] = useState('')
    let [descru, setDescRu] = useState('')

    const onFinish = (values) => {
        let data = { ...values, page_uz: descuz, page_ru: descru }
        addPage(data)
    }

    return (
        <Form
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
        >
            <fieldset>
                <legend>Umumiy Informatsiya</legend>

                <Form.Item
                    label="Nomi (O'zbekcha)"
                    name="name_uz"
                    rules={[{ required: true, message: 'Please enter the product name (English)' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nomi (Ruscha)"
                    name="name_ru"
                    rules={[{ required: true, message: 'Please enter the product name (Russian)' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Batafsil (O'zbekcha)"
                    name="page_uz"
                >
                    <SunEdit setter={setDescUz} />
                </Form.Item>

                <Form.Item
                    label="Batafsil (Ruscha)"
                    name="page_ru"
                >
                    <SunEdit setter={setDescRu} />
                </Form.Item>
            
            </fieldset>
            <fieldset>
                <legend>Yorliqlar</legend>
                <Row gutter={10}>
                    <Col span={4}>
                        <Form.Item
                            label="Sahifani ko'rsatish"
                            name="status"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item></Col>
                   
                    <Col span={4}>
                        <Form.Item
                            label="Ssilka"
                            name="link"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </fieldset>

            <fieldset>
                <legend>SEO sozlamalari</legend>

                <Form.Item
                    label="SEO nomi"
                    name="meta_title"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="SEO kalit so'zlar"
                    name="meta_keywords"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="SEO tavsifi"
                    name="meta_description"
                >
                    <Input.TextArea />
                </Form.Item>

            </fieldset>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Saqlash
                </Button>
            </Form.Item>

        </Form>
    )
}

export default Add;