import { Button, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useUnits } from './Store';
import { useForm } from 'antd/es/form/Form';

function Edit(props) {
    let { updateUnit, ed } = useUnits()
    let [form] = useForm()
    useEffect(() => {
        form.setFieldsValue(ed)
    }, [ed])

    return (
        <>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={updateUnit}
                autoComplete="off"
            >
                <Form.Item
                    label="ID"
                    name="id"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="O'lchov birlik nomi UZ"
                    name="name_uz"
                    rules={[{ required: true, message: "Iltimos, o'lchov birlik nomini kiriting" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="O'lchov birlik nomi RU"
                    name="name_ru"
                    rules={[{ required: true, message: "Iltimos, o'lchov birlik nomini kiriting" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="O'lchov birlik qisqartmasi UZ"
                    name="slug_uz"
                    rules={[{ required: true, message: "Iltimos, o'lchov birlik qisqartmasini kiriting" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="O'lchov birlik qisqartmasi RU"
                    name="slug_ru"
                    rules={[{ required: true, message: "Iltimos, o'lchov birlik qisqartmasini kiriting" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default Edit;