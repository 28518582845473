import React, { useEffect } from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import Add from './Add';
import { useCategorys } from './Store';
import Edit from './Edit';

function Categorys(props) {
    let {columns, categorys, setCategorys, removeCategory, setEd} = useCategorys()

    useEffect(() => {
        setCategorys()
    }, [])
    return (
        <div>

            <MainTable
                pageTitle={'Kategoriyalar'}
                defkey={'id'}
                pcolumns={columns}
                pdata={categorys}
                add={<Add />}
                onDelete={removeCategory}
                setEd={setEd}
                edit={<Edit />}
                onEdit={(rec)=>{
                    setEd(rec)
                }}
            />
        </div>
    );
}

export default Categorys;