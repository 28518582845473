import React, { useEffect } from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import { usePages } from './Store';
import Add from './Add';
import Edit from './Edit';

function Pages(props) {
    let { pages, columns, setPages, deletePage, setEd } = usePages()

    useEffect(() => {
        setPages()
    }, [])
    return (
        <div>
            <MainTable
                pageTitle={"Sahifalar"}
                pdata={pages}
                pcolumns={columns}
                add={<Add />}
                onDelete={deletePage}
                setEd={setEd}
                defkey={'id'}
                onEdit={(ed) => {
                    setEd(ed)
                }}
                edit={<Edit />}
            />
        </div>
    );
}

export default Pages;