import { Card, Col, Divider, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import OrderCard from './OrderCard';
import axios from 'axios';

function Orders(props) {
    let [oreders, setOrders] = useState([])

    useEffect(() => {
        axios.get('/orders').then(res => {
            setOrders(res.data)
        })
    }, [])

    return (
        <div>
            <h2>Buyurtmalar</h2>
            <Divider />
            <Row gutter={16}>
                {oreders.map(item => (
                    <Col key={item.id} span={8} style={{ marginBottom: '15px' }}>
                        <OrderCard order={item} />
                    </Col>
                ))}

            </Row>
        </div>
    );
}

export default Orders;